import { useEffect, useState } from 'react';
// @mui
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
// hooks
import { useQuery } from 'react-query';
import marketService from '../../../pages/dashboard/market/services/market.service';

// ----------------------------------------------------------------------

export default function MarketSwitcher() {
    const [selectedMarket, setSelectedMarket] = useState(localStorage.getItem('market') || "0");

    const { data, isLoading } = useQuery({
        queryKey: ['markets-switcher'],
        queryFn: () => marketService.getMarkets()
    });

    useEffect(() => {
        if (data && data.length > 0) {
            if (!selectedMarket || !data.some(market => market.id === selectedMarket)) {
                setSelectedMarket(0);
                localStorage.setItem('market', "0");
            }
        }
    }, [data]);

    const handleChange = (event) => {
        const newMarketId = event.target.value;
        setSelectedMarket(newMarketId);
        localStorage.setItem('market', newMarketId);
    };

    return (
        <FormControl>
            <InputLabel id="market-label">Market</InputLabel>
            <Select
                value={selectedMarket}
                label="Market"
                onChange={handleChange}

                inputProps={{ 'aria-label': 'Select market' }}
                sx={{ height: 40 }}
            >
                <MenuItem value={"0"} key={"0"}>
                    All
                </MenuItem>
                {isLoading ? (
                    <MenuItem value={""} key={""}>Loading...</MenuItem>
                ) : (


                    data?.map((option) => (
                        <MenuItem key={option.id?.toString()} value={option.id?.toString()}>
                            {option.name}
                        </MenuItem>
                    ))

                )}
            </Select>
        </FormControl>
    );
}
