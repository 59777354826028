
import axiosInstance from "../../../../utils/axios";


export default {
    getMarkets: async () => {
        const response = await axiosInstance.get('/markets/markets');
        return response.data;
    },
    create: async (data) => {
        const response = await axiosInstance.post('/markets', data);
        return response.data;
    },
    findAll: async () => {
        const response = await axiosInstance.get('/markets');
        return response.data;
    },
    findOne: async (id) => {
        const response = await axiosInstance.get(`/markets/${id}`);
        return response.data;
    },
    update: async ({ id, data }) => {
        const response = await axiosInstance.patch(`/markets/${id}`, data);
        return response.data;
    },
    delete: async (id) => {
        const response = await axiosInstance.delete(`/markets/${id}`);
        return response.data;
    },
    findAllCities: async (countryId) => {
        const response = await axiosInstance.get(`/markets/cities/${countryId}`);
        return response.data;
    },
    createCity: async ({ countryId, data }) => {
        const response = await axiosInstance.post(`/markets/cities/${countryId}`, data);
        return response.data;
    },
    updateCity: async ({ id, data }) => {
        const response = await axiosInstance.patch(`/markets/cities/${id}`, data);
        return response.data;
    },
    deleteCity: async (id) => {
        const response = await axiosInstance.delete(`/markets/cities/${id}`);
        return response.data;
    },

}